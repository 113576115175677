/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import Bg from '../../images/contact_us_form.svg';

export const contactUsStyles = makeStyles((theme) => ({
  container: {
    background: `url(${Bg}) right bottom no-repeat`,
    height: '240px',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
      height: '216px',
      backgroundPositionX: '63%',
    },
    '& > h1': {
      padding: '4px 24px',
      color: '#fff',
      borderRadius: 8,
      background: '#1c1c1c',
      display: 'inline-block',
      font: `700 36px/60px ${theme.typography.fontFamily} !important`,
      '@media (max-width: 1024px)': {
        padding: '12px',
        font: `700 24px/24px ${theme.typography.fontFamily} !important`,
      },
    },
  },
}));

export const materialsSalesStyles = makeStyles(() => ({
  wrapper: {
    textAlign: 'center',
    padding: '88px 0',
    color: '#1C1C1C',
    '@media (max-width: 1024px)': {
      padding: '72px 0',
    },
  },
  headline: {
    fontWeight: 900,
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  subtitle: {
    fontWeight: 700,
    '@media (max-width: 1024px)': {
      width: 138,
    },
  },
  section: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 1024px)': {
      alignItems: 'stretch',
      flexWrap: 'wrap',
      marginTop: 40,
    },
  },
  container: {
    width: 'auto',
    marginBottom: 'initial',
    '@media (max-width: 1024px)': {
      width: '45%',
      marginBottom: '20px',
    },
  },
  item: {
    marginBottom: '40px',
    '@media (max-width: 1024px)': {
      marginBottom: '20px',
    },
  },
  img: {
    width: 184,
    height: 184,
    '@media (max-width: 1024px)': {
      width: 138,
      height: 138,
    },
  },
}));

export const faqStyles = makeStyles(() => ({
  faqHeroWrapper: {
    '@media (max-width: 1024px)': {
      height: 142,
    },
  },
  container: {
    '@media (max-width: 1024px)': {
      padding: 0,
    },
  },
}));

export const partnerStyles = makeStyles(() => ({
  partnerHeroWrapper: {
    height: 314,
    '@media (max-width: 1024px)': {
      height: 250,
    },
  },
  container: {
    '@media (max-width: 1024px)': {
      padding: 0,
    },
  },
}));
