// @flow
import React from 'react';
import { Container } from '@material-ui/core';
import NewApplySection from 'src/components/Merchant/NewApplySection';
import Layout from '../../../components/Layout/Layout';
import Hero from '../../../components/Merchant/shared/Hero';
import bg from '../../../images/materials.svg';
import bgSp from '../../../images/faq-sp.svg';
import FAQLoader from '../../../components/Merchant/FAQ/FAQLoader';
import { faqStyles } from '../../../styles/pages/merchant.styles';

const FaqPage = () => {
  const classes = faqStyles({});

  return (
    <Layout isMerchant>
      <Hero
        title="よくあるご質問"
        bg={bg}
        bgSp={bgSp}
        classNames={classes.faqHeroWrapper}
      />
      <Container className={classes.container}>
        <FAQLoader />
      </Container>
      <NewApplySection />
    </Layout>
  );
};

// $FlowFixMe
export default FaqPage;
