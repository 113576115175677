import { Box, Container } from '@material-ui/core';
import React from 'react';
import LinearCTAButton from '../LinearCTAButton';
import styles from './NewApplySection.module.scss';

export default function NewApplySection() {
  return (
    <section className={styles.container}>
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          gridGap={{ xs: 16, md: 32 }}
          width="100%"
          flexWrap="wrap"
        >
          <LinearCTAButton
            text="導入のお申し込みはこちら"
            to="/merchant/application/#documents"
          />
          <LinearCTAButton text="資料請求" to="/merchant/materials/sales/" />
        </Box>
      </Container>
    </section>
  );
}
