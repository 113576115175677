// @flow
import React from 'react';
import NewFAQ from './NewFAQ';
import styles from './FAQLoader.module.scss';

const categories = [{ name: '導入のお申し込みについて' }, { name: 'その他' }];
const answers = [
  {
    question: '申し込みから利用開始までの期間を教えてください',
    answer:
      'お申し込みフォームの提出後、1週間程度（目安）で審査結果をメールにてご連絡いたします。審査を通過すると、ペイディ加盟店アカウントを発行します。',
    category: { name: '導入のお申し込みについて' },
  },
  {
    question: '加盟店審査の内容について教えてください',
    answer:
      '具体的な審査基準に関してはお答えできませんので、予めご了承ください。',
    category: { name: '導入のお申し込みについて' },
  },
  {
    question: '個人事業主でも利用できますか',
    answer: 'ご利用いただけます。',
    category: { name: '導入のお申し込みについて' },
  },
  {
    question: '海外法人でも利用できますか',
    answer: 'ご利用いただけません。',
    category: { name: '導入のお申し込みについて' },
  },
  {
    question: '取り扱いの商材や業種に制限はありますか',
    answer:
      '公序良俗に反する商材のお取り扱いはできません。そのほか、お申し込みフォームの内容を元に審査させていただき、場合によってはお断りさせていただく場合がございます。',
    category: { name: '導入のお申し込みについて' },
  },
  {
    question: 'サイトがない、あるいは構築中でも申し込みは可能ですか',
    answer: (
      <ul className={styles.ul}>
        <li>
          通常加盟店：サイト構築中でも審査は可能です。お申し込み後、特商法、プライバシーポリシー、商材一覧（金額と商材）を別途ご提示ください。
        </li>
        <li>
          定期購入加盟店：サイト構築中の場合は審査ができません。サイトが完成してから改めてお申込みください。
        </li>
      </ul>
    ),
    category: { name: '導入のお申し込みについて' },
  },
  {
    question: '導入にかかる費用を教えてください',
    answer:
      '導入のための費用は発生いたしません。固定費用や月額費用もございません。ペイディのご利用に必要な費用は決済手数料のみです。',
    category: { name: '導入のお申し込みについて' },
  },
  // その他
  {
    question: '決済手数料はいくらですか',
    answer: (
      <>
        決済手数料は個別にご連絡させて頂いております。
        <a href="mailto:sales@paidy.com" className={styles.link}>
          sales@paidy.com
        </a>
        までお気軽にお問い合わせください。
      </>
    ),
    category: { name: 'その他' },
  },
  {
    question: '入金サイクルを教えてください',
    answer: (
      <>
        ペイディの入金サイクルは、売上が確定した決済が対象となります。
        <br />
        月末締め、翌月20日にペイディから加盟店様へお支払いとなります。
        <br />
        <br />
        ※加盟店手数料と入金手数料を差し引いて入金いたします。
        <br />
        ※返金処理された場合、翌月20日の立替金から相殺いたします。
      </>
    ),
    category: { name: 'その他' },
  },
  {
    question: '入金手数料はかかりますか',
    answer: 'はい。550円（税込）がかかります。',
    category: { name: 'その他' },
  },
  {
    question: 'テスト環境はありますか',
    answer: (
      <>
        Paidy
        APIにはテスト環境と本番環境という切り分けはなく、テストAPIキーと本番APIキーをご用意しております。
        <br />
        <br />
        テスト用APIキーをご利用いただくことで、テスト決済を作成・更新することが可能です。
        <br />
        <br />
        テストアカウントは、決済成功と決済失敗の２つのみとなります。
        <br />
        ・決済成功アカウント「successful.payment@paidy.com」
        <br />
        ・決済失敗アカウント「rejected.payment@paidy.com」
      </>
    ),
    category: { name: 'その他' },
  },
];

export default function FAQLoader() {
  return <NewFAQ categories={categories} answers={answers} />;
}
